/**
 * Module dependencies
 */
const React = require('react');
const { object, string, oneOfType } = require('prop-types');

const CircledIcon = ({ svg, className }) => {
  if (svg === '') {
    return null;
  }
  if (typeof svg === 'string') {
    return <div className={`circled-icon ${className}`} data-testid="string-circled-icon" dangerouslySetInnerHTML={{ __html: svg }} />;
  }
  if (React.isValidElement(svg)) {
    return (
      <div className={`circled-icon ${className}`} data-testid="svg-circled-icon">
        { svg }
      </div>
    );
  }
  return null;
};

/**
 * Define props
 */
CircledIcon.propTypes = {
  svg: oneOfType([object, string]),
  className: string,
};

CircledIcon.defaultProps = {
  className: '',
  svg: '',
};

module.exports = CircledIcon;
