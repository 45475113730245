const React = require('react');
const { string, arrayOf, object } = require('prop-types');
const { Card, CardHeader, CardContent, CardFooter} = require('@andes/card');
const { List, ListItem } = require('@andes/list');

// const ActivityList = require('../Activities/components/ActivityList');

const CXActivityCard = ({ activities, header, footer, linkRedirect, buildMelidataTrack }) => {
  // const { type } = device;

  const cardOnClick = () => {
    const melidataTrack = buildMelidataTrack('/MPFRONT/ACTIVITIES');
    if (typeof window !== 'undefined' && window.melidata && melidataTrack) {
      window.melidata('cleanAndSend', 'event', melidataTrack);
    }
  };

  if (activities && activities.length > 0) {
    return (
      <Card className="peach__activities__card">
        <CardHeader>
          <h2>{header}</h2>
        </CardHeader>
        <CardContent>
          <div>{/* <ActivityList activities={activities} deviceType={type} buildMelidataTrack={buildMelidataTrack} /> */}</div>
        </CardContent>
        <CardFooter>
          <List srLabel="Activities" onClick={(e) => cardOnClick(e)}>
            <ListItem data-testid="activity-card-footer" title={footer} href={linkRedirect} chevron />
          </List>
        </CardFooter>
      </Card>
    );
  }

  return null;
};

CXActivityCard.propTypes = {
  activities: arrayOf(object).isRequired,
  device: string,
  header: string,
  footer: string,
  linkRedirect: string.isRequired,
};

CXActivityCard.defaultProps = {
  device: '',
  header: '',
  footer: '',
};

module.exports = CXActivityCard;
