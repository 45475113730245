/**
 * Module dependencies
 */
const React = require('react');
const { string, bool, shape, arrayOf, object, objectOf, any } = require('prop-types');
const { Script } = require('nordic/script');
const Style = require('nordic/style');
const RealEstatesWeb = require('merch-realestates-web');
const { useI18n } = require('../../utils/context/i18nContext');
const classNames = require('classnames');

const ContentList = require('../../components/ContentList');
const CardList = require('../../components/CardList');
const CXActivityCard = require('../../components/CXActivityCard');


const { buildMelidataTrack } = require('./utils');
const { useUrlState } = require('../../utils/context/url');
const { Text } = require('@andes/typography');
const {useNavigationDetailsHandler} = require('../../hooks/useNavigationDetailsHandler');

const View = (
  {
    content,
    webView,
    styleFile,
    transactionalData,
    state,
    transactionalCardData,
    device,
    hasCards,
    userWithActivitiesCardEnabled,
    platform,
    furyConfig,
    breadcrumbLevels,
    nativeAppNavigation,
  },
) => {
  const { headers = {} } = useUrlState();
  const portalPrefix = headers['x-portal-prefix'];
  const i18n = useI18n();
  const trackMelidata = state.page.melidata;
  const showActivityCard = transactionalCardData && transactionalCardData.show;
  const cardHeaderText = i18n.gettext('Preguntas frecuentes');
  let showCardHeader = hasCards;
  const { displayLevelTitle, levelTitle } = useNavigationDetailsHandler({ breadcrumbLevels, nativeAppNavigation });

  if (userWithActivitiesCardEnabled) {
    showCardHeader = transactionalCardData && transactionalCardData.show;
  }

  const buildActivitiesMelidataTrack = (path) => {
    const melidataTrack = {
      path: '/portal/folder/click',
      event_data: {
        portal_content_id: content.contentId,
        portal_content_destination_url: path,
      },
    };
    return melidataTrack;
  };

  const merchConfig = furyConfig?.merchReConfig[portalPrefix || platform?.id.toLowerCase()]?.dynamic;
  const merchContent = furyConfig?.merchReWebIds[platform?.id.toLowerCase()]?.[content?.contentId];
  const merchCustomParams = { category: merchContent || `portal_${content?.contentId}` };
  return (
    <div className="cx-peach-content folder">
      <Style href={styleFile} critical={webView === 'mobile'} />
      <Script src="vendor.js" />
      <Script src={`folder.${webView ? 'mobile' : 'desktop'}.js`} />
      <Script>
        {`
          var agentKey = '_perfill';
          window[agentKey] = window[agentKey] || function (...args) {
            (window[agentKey].q = window[agentKey].q || []).push(args);
          };
          var perfillAgent = window[agentKey];
          perfillAgent('queue', 'start-recording', {
            traceability_id: 'folder_id-${content.contentId}',
          });
        `}
      </Script>
      {displayLevelTitle && <Text component="p" size="s" color="secondary" className="folder__level-title">
        {levelTitle}
      </Text>}
      <h1 className={classNames('folder__title', { 'folder__title--no-padding': displayLevelTitle })}>{content.secondTitle || content.title}</h1>
      {merchConfig && (<RealEstatesWeb id={merchConfig} customParams={merchCustomParams} className="contingency" />)}
      { hasCards && !userWithActivitiesCardEnabled && <CardList contents={content.cards} inFolder showChevron buildMelidataTrack={buildMelidataTrack(trackMelidata, transactionalData)} />}
      {showActivityCard
        && (
          <CXActivityCard
            activities={transactionalCardData && transactionalCardData.transactions}
            header={transactionalCardData && transactionalCardData.title}
            footer={transactionalCardData && transactionalCardData.footer}
            device={device}
            linkRedirect={transactionalCardData && transactionalCardData.redirectLink}
            buildMelidataTrack={buildActivitiesMelidataTrack}
          />
        )}
      <ContentList
        transactionalCardData={transactionalCardData}
        data={content}
        header={showCardHeader && cardHeaderText}
        showChevron
        buildMelidataTrack={buildMelidataTrack(trackMelidata, transactionalData)}
      />
    </div>
  );
};

View.propTypes = {
  content: shape({
    title: string,
    contents: arrayOf(object),
  }).isRequired,
  webView: bool.isRequired,
  styleFile: string.isRequired,
  state: objectOf(any).isRequired,
  transactionalCardData: shape({
    transactions: arrayOf(object),
    enabled: bool,
  }),
  hasCards: bool,
  userWithActivitiesCardEnabled: bool,
};

View.defaultProps = {
  transactionalCardData: null,
  hasCards: false,
  userWithActivitiesCardEnabled: false,
};

module.exports = View;
