/**
 * Module dependencies
 */
const React = require('react');
const classNames = require('classnames');
const { arrayOf, object, bool, func } = require('prop-types');

const { Card } = require('@andes/card');
const { ListItem, List } = require('@andes/list');

const CircledIcon = require('./CircledIcon');
const { useFlags } = require('../../utils/context/flags');

const getTrackDetailsByType = (trackingType, content) => {
  if (trackingType === 'folder') {
    return {
      path: '/portal/folder/click',
      event_data: {
        portal_content_destination_url: content.href,
        portal_content_id: content.content_id,
      },
    };
  }

  return null;
};

const CardList = ({
  title,
  contents,
  showChevron,
  inFolder,
  buildMelidataTrack,
  trackingType,
}) => {
  if (!contents.length) return null;

  const { 'regulatory-text-mp': regulatoryText } = useFlags();

  const sendMelidataTrack = (content) => {
    let melidataTrack = null;

    if (buildMelidataTrack) {
      melidataTrack = buildMelidataTrack(content);
    } else if (trackingType) {
      melidataTrack = getTrackDetailsByType(trackingType, content);
    }

    if (typeof window !== 'undefined' && window.melidata && melidataTrack) {
      window.melidata('cleanAndSend', 'event', melidataTrack);
    }
  };

  const onClickEvent = (content) => {
    sendMelidataTrack(content);

    if (content.onClick) content.onClick();
  };

  return (
    <div className={classNames('cx-card-list', { 'cx-card-list--in-folder': inFolder })}>
      {title && title.length > 0 && <h2 className="cx-peach-home__subtitle section-title">{title}</h2>}
      {contents.map(content => (
        <Card paddingSize={0} key={content.content_id} shadow="flat" className="cx-card-list__card-item">
          <List srLabel={content.title} onClick={() => onClickEvent(content)}>
            <ListItem
              className={classNames('cx-card-list__item', { 'cx-card-list__item--regulatory-text': regulatoryText })}
              leftContent={<CircledIcon svg={content.mobile_icon} />}
              title={content.title}
              description={content.description}
              href={content.href}
              chevron={showChevron}
              data-testid={content.title}
            />
          </List>
        </Card>
      ))}
    </div>
  );
};

/**
 * Define props
 */
CardList.propTypes = {
  contents: arrayOf(object).isRequired,
  buildMelidataTrack: func,
  showChevron: bool,
  inFolder: bool,
};

CardList.defaultProps = {
  showChevron: false,
  inFolder: false,
};

module.exports = CardList;
