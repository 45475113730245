exports.buildMelidataTrack = (trackMelidata, transactionalData) => (trackingContent) => {
  if (trackingContent.type !== 'REDIRECT') {
    return null;
  }
  const melidataTrackData = {
    ...trackMelidata,
    path: `${trackMelidata.path}/click`,
    event_data: {
      ...trackMelidata.event_data,
      portal_content_destination_url: trackingContent.href,
      portal_content_transactional_data: transactionalData,
    },
  };
  return melidataTrackData;
};

exports.nativeDeeplinks = {
  ML_HELP_DEEPLINK: 'meli://cx/help?url=',
  MP_HELP_DEEPLINK: 'mercadopago://cx/help?url=',
};
