/**
 * Styles
 */
require('../../pages/folder/styles.scss');

/**
 * Module dependencies
 */
const startApp = require('../start-app');
const FolderView = require('../../pages/folder/view');

startApp(FolderView);
