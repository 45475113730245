const React = require('react');
const { useFlags } = require('../utils/context/flags');
const { useDeviceState } = require('../utils/context/device');
const { useI18n } = require('../utils/context/i18nContext');

const PORTAL_COUNTER_THRESHOLD = 2;
const BREADCRUMB_LEVEL_THRESHOLD = 3;

const useNavigationDetailsHandler = ({ breadcrumbLevels, nativeAppNavigation }) => {
  if (typeof window === 'undefined') {
    return {
      displayLevelTitle: '',
      levelTitle: '',
    };
  }

  const { type: deviceType, nativeApp } = useDeviceState();
  const { 'redesign-categories-contexts': redesignCategoriesContexts } = useFlags();
  const [levelTitle, setLevelTitle] = React.useState(null);
  const i18n = useI18n();
  const isMobile = deviceType === 'mobile';

  const getLevelTitle = React.useCallback(() => {
    if (nativeApp && nativeAppNavigation && nativeAppNavigation.portalCounter > PORTAL_COUNTER_THRESHOLD) {
      return nativeAppNavigation.folderLevelTitle;
    }

    if (isMobile && breadcrumbLevels.length >= BREADCRUMB_LEVEL_THRESHOLD) {
      return breadcrumbLevels[1].text;
    }

    return false;
  }, [breadcrumbLevels, isMobile, nativeApp, nativeAppNavigation]);

  const getBarTitle = React.useCallback(() => {
    if (nativeApp && nativeAppNavigation && nativeAppNavigation.portalCounter > 1 && nativeAppNavigation.homeLevelTitle) {
      return nativeAppNavigation.homeLevelTitle;
    }

    return false;
  }, [nativeApp, nativeAppNavigation]);

  React.useEffect(() => {
    if (!redesignCategoriesContexts) return undefined;

    setLevelTitle(getLevelTitle());
    const barTitle = getBarTitle();
    if (barTitle && window && window.MobileWebKit && window.MobileWebKit.topbar) {
      const helpText = i18n.gettext('Ayuda sobre'); // add i18n
      window.MobileWebKit.topbar.setup({
        bar_title: `${helpText} ${barTitle}`,
      });
    }
  }, [getBarTitle, getLevelTitle, i18n, redesignCategoriesContexts]);

  return {
    displayLevelTitle: Boolean(levelTitle),
    levelTitle,
  };
};

module.exports = {
  useNavigationDetailsHandler,
};
